import {
    getGoldTypeByCode,
    GoldType_Unknown,
    readGoldWeightUnits,
    readMoneyInVietnameseDongWithMaxLength
} from "../constants";
import JsBarcode from "jsbarcode";

export const handlePrinting = async (pawnData = {}) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
          <html lang="en-US">
              <head>
                <link rel="icon" href="/logo.png">
                <style>
                    .body-stamp {
                        width: 210mm;
                        height: 145mm;
                        font-size: 1.1em;
                        white-space: nowrap;
                        display: flex;
                        justify-content: space-between;
                         /* border: 1px solid #000; */
                        font-family: Tahoma, sans-serif;
                    }
                    
                    .left-col {
                        width: 63mm;
                        padding-left: 5px; 
                        line-height: 1.5em;
                    }
                    .right-col {
                        padding-right: 5px;
                        width: 139mm;
                        line-height: 1.8em;
                    }
                    
                    .col-full-width {
                        width: 100%
                    }
                    
                    .col-half-width {
                        width: 100%
                    }
                    .right-col .title {
                        width: 26%
                    }
                    .right-col .title-name {
                        width: 32%
                    }
                    .right-col .content {
                        width: 74%
                    }
                    .right-col .content-name {
                        width: 68%
                    }
                    .right-col .content-date {
                        display: flex;
                        justify-content: space-between;
                    }
                    .col-with-col {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .f-bold {
                        font-weight: bold;
                    }
                    .t-right {
                        text-align: right;
                    }
                    .t-center {
                        text-align: center;
                    }
                    @media print {
                        body {
                            margin: 0; /* Remove body margin */
                        }
                        @page {
                            size: 210mm 145mm; 
                            margin: 0; /* Remove any default margins */
                        }
                    }
                    svg {
                      height:60px;
                      width: 150px;
                    }
                    .content-overflow {
                        white-space: nowrap; 
                        overflow: hidden; 
                        text-overflow: ellipsis;
                    }
                </style>
                <title>In Biên Nhận Cầm Đồ</title>
              </head>
              <body>
                <div class="body-stamp">
                    <div class="left-col">
                        <div class="col-with-col" style="height: 31mm">
                            <div class="col-half-width f-bold" style="margin-left: 25px; margin-top: 10px;"><span>${pawnData.pawnDate.format('DD/MM')}</span></div> 
                            <div class="col-half-width t-right"></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width t-right"><span>${pawnData.pawnDate.format('HH:mm DD/MM/YYYY')}</span></div>
                            <div class="col-half-width t-right" style="width: 50%;"><span>${pawnData.pawnId}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width"></div>
                            <div class="col-half-width t-right">MSKH: ${pawnData.customerId}</div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width"></div>
                            <div class="col-half-width t-right"><span><span>${pawnData.customerName}</span></span></div>
                        </div>
                        <div class="col-with-col" style="height: 8mm"></div>
                        <div class="col-with-col">
                            <div class="col-half-width t-right content-overflow"><span>${pawnData.itemName}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width"></div>
                            <div class="col-half-width t-right"><span>V: ${readGoldWeightUnits(pawnData.itemWeight)}${pawnData.gemWeight ? ', H:' + readGoldWeightUnits(pawnData.gemWeight) : ''}${(getGoldTypeByCode(pawnData.itemType).code !== GoldType_Unknown.code) ? ' - ' + getGoldTypeByCode(pawnData.itemType).label : ''}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width"></div>
                            <div class="col-half-width t-right"><span>${Number(pawnData.pawnAmount).toLocaleString()}vnđ</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width"></div>
                            <div class="col-half-width t-right"><span>${pawnData.pawnDueDate.format('DD/MM/YYYY')}</span></div>
                        </div>
                    </div>
                    <div class="right-col">
                        <div class="col-with-col" style="height: 38mm">
                            <div class="col-with-col">
                                <div class="col-half-width">
                                    <div class="col-with-col" style="height: 20mm"></div>
                                    <svg id="barcode-container"></svg>
                                </div>
                                <div class="col-half-width t-right">
                                </div>
                            </div>
                        </div>
                        <div class="col-with-col">
                            <div class="title"></div>
                            <div class="content t-right"><span>${pawnData.pawnId}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="title-name"></div>
                            <div class="content-name"><span>${pawnData.customerName}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="title"></div>
                            <div class="content content-overflow"><span>${pawnData.itemName}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="title"></div>
                            <div class="content"><span>V: ${readGoldWeightUnits(pawnData.itemWeight)}${pawnData.gemWeight ? ', H:' + readGoldWeightUnits(pawnData.gemWeight) : ''} ${(getGoldTypeByCode(pawnData.itemType).code !== GoldType_Unknown.code) ? ' - ' + getGoldTypeByCode(pawnData.itemType).label : ''}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="title"></div>
                            <div class="content"><span>${Number(pawnData.pawnAmount).toLocaleString()}(${readMoneyInVietnameseDongWithMaxLength(pawnData.pawnAmount, 37)})</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="title"></div>
                            <div class="content"><span>${pawnData.pawnDueDate.format('DD/MM/YYYY')}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="title"></div>
                            <div class="content t-right content-date" style="line-height: 1.2em"><div style="width: 270px"><span>${pawnData.pawnDate.format('DD')} </span></div><div style="width: 75px"><span>${pawnData.pawnDate.format('MM')} </span></div><div style="width: 85px"><span>${pawnData.pawnDate.format('YYYY')} </span></div></div>
                        </div>
                    </div>
                </div>
              </body>
          </html>
        `);
    JsBarcode(printWindow.document.getElementById("barcode-container"), `${pawnData.pawnId}`, {
        displayValue: true,
        width: 2,
        height: 40,
        margin: 0,
    });
    printWindow.document.close();
    printWindow.print();
}
