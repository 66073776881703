import {findGoldBrandNameByCode, getShopInfo, readGoldWeightStamp} from "../constants";
import JsBarcode from "jsbarcode";

export const handlePrinting = async (jewelryData = {}) => {
    const printWindow = window.open('', '_blank');
    const shopInfo = getShopInfo();
    const brand = findGoldBrandNameByCode(jewelryData.brand);
    printWindow.document.write(`
          <html lang="en-US">
              <head>
                <link rel="icon" href="/logo.png">
                <style>
                    .jewelry-stamp {
                        width: 41mm;
                        height: 11mm;
                        white-space: nowrap;
                        display: flex;
                        justify-content: space-between;
                        /*border: 1px solid #000;*/
                        font-family: Tahoma, sans-serif;
                        padding-top: 2px;
                    }
                    
                    .column {
                        width: 49.5%;
                    }
                    
                    .left-block {
                        width: 78px;
                        padding-right: 1px;
                        padding-top: 1px;
                        font-size: 4.5pt;
                        line-height: 1.15em;
                    }
                    .right-block {
                        width: 75px;
                        font-size: 4.2pt !important;
                        line-height: 1.2em;
                    }
                    
                    .col-full-width {
                        width: 100%
                    }
                    
                    .col-half-width {
                        width: 100%
                    }
                    
                    .col-with-col {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .f-bold {
                        font-weight: bold;
                    }
                    .t-right {
                        text-align: right;
                    }
                    .t-center {
                        text-align: center;
                    }
                    .f-shop-name {
                        text-align: center;
                        font-size: 4pt !important;
                    }
                    .f-name {
                        font-size: 5.5pt !important;
                        font-weight: bold;
                    }
                    .f-gold-weight {
                        font-size: 6pt !important;
                        font-weight: bold;
                    }
                    .content-overflow {
                        white-space: nowrap; 
                        overflow: hidden; 
                        text-overflow: ellipsis;
                    }
                    @media print {
                        body {
                            margin: 0; /* Remove body margin */
                        }
                   
                        @page {
                            size: 42mm 12mm; /* 4.1cm x 1cm */
                            margin: 0; /* Remove any default margins */
                        }
                    }
                    svg {
                      height:8px;
                      width: 30px;
                    }
                </style>
                <title>In tem [${jewelryData.name}]</title>
              </head>
              <body>
                <div class="jewelry-stamp">
                    <div class="left-block">
                        <div class="col-with-col">
                            <div class="col-half-width f-name"><span>${jewelryData.name}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width" style="width: 55% !important;"><span>KLT:${readGoldWeightStamp(jewelryData.totalWeight)}</span></div>
                            <div class="col-half-width t-center" style="width: 45% !important;"><span>HLV:${jewelryData.goldType}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width f-gold-weight"><span>KLV:${readGoldWeightStamp(jewelryData.goldWeight)}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div style="width: 55% !important;">
                                <div class="col-half-width"><span>KLH:${readGoldWeightStamp(jewelryData.gemWeight)}</span></div>
                                <div class="col-half-width"><span>CB:${Number(jewelryData.sellProcPrice).toLocaleString()}đ</span></div>
                                <div class="col-half-width"><span>CĐ:${jewelryData.exchangeProcPrice ? Number(jewelryData.exchangeProcPrice).toLocaleString() + 'đ' : ''}</span></div>
                            </div>
                            <div style="width: 45% !important;">
                                <div class="col-half-width f-bold"><span>${jewelryData.jewelryId}</span></div>
                                <div class="col-half-width"><svg id="barcode-container" width="100%" height="100%"></svg></div>
                            </div>
                        </div>
                    </div>
                    <div class="right-block">
                      <div class="col-full-width f-shop-name"><span>${shopInfo.shortName}</span></div>
                      <div class="col-full-width"><span>${shopInfo.shortAddress}</span></div>
                      <div class="col-with-col"><span>NSX:${brand.shortName}</span></div>
                      <div class="col-full-width"><span>${brand.address ? brand.address : ''}</span></div>
                      <div class="col-with-col">
                            <div class="col-half-width"><span>TCCS:${brand.pubStand}</span></div>
                            <div class="col-half-width t-right"></div>
                        </div>
                       <div class="col-with-col">
                            <div class="col-half-width"><span>Xuất xứ:${brand.origin}</span></div>
                            <div class="col-half-width t-right">KH:${jewelryData.symbol}</div>
                        </div>
                    </div>
                </div>
              </body>
          </html>
        `);
    JsBarcode(printWindow.document.getElementById("barcode-container"), `${jewelryData.jewelryId}`, {
        displayValue: false,
        width: 4,
        height: 60,
        margin: 0,
    });
    printWindow.document.close();
    printWindow.print();
}

